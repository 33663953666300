<template>
  <div class="dialog-frame">
    <div class="ui-corner-all dialog">
      <div v-if="!success">
        <h1 class="salutation fontFamily">Hello</h1>
        <br />
        <p id="unsubscribeData" class="fontSizeP fontFamily">
          If you wish to unsubscribe from further communications please enter
          your email address, then choose unsubscribe below
        </p>
        <br /><br />
        <div class="flex-col-2">
          <input
            class="email"
            v-model="mailId"
            placeholder="Please enter your email"
          />
          <span style="color: red">{{ errorMsg }}</span>
        </div>
        <div id="emailIdRequired"></div>
        <div style="text-align: center">
          <button
            v-on:click="validateSubmit()"
            class="submitBtn green fontFamily"
            style="margin-top: 25px; height: 35px"
          >
            Unsubscribe
          </button>
        </div>
      </div>
      <div v-else>
        <h1 class="salutation fontFamily">Unsubscribed</h1>
        <br />
        <p id="unsubscribeData" class="fontSizeP fontFamily">
          You have been successfully unsubscribed from email communication
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "unsubscribe",
  data() {
    return {
      counter: 0,
      mailId: "",
      errorMsg: "",
      success: "",
    };
  },
  methods: {
    async validateSubmit() {
      //eslint-disable-next-line
       if (!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.mailId)) {
        this.errorMsg = "Please enter a valid email address";
      } else if (!this.mailId) {
        this.errorMsg = "Field is Required";
      } else {
        await this.$store
          .dispatch("unsubscribe/mail", { emailId: this.mailId })
          .then((msg) => {
            if (msg === "SUCCESS") {
              this.success = "sucess";
            }
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

body {
  font-family: verdana, tahoma, sans-serif, arial;
}

h1 {
  font-size: 150%;
  font-weight: bold;
  padding-bottom: 10px;
}

.dialog-frame {
  width: 600px;
  height: 200px;
  margin: 100px auto;
}
.pcsnewlook {
  color: #62a744;
  font-size: 16px;
  font-family: Titillium;
  font-weight: 600;
  line-height: 33px;
}
.dialog {
  background-color: #eeeeee;
  border: 2px solid #ffffff;
  padding: 15px;
  font-size: 80%;
}

.ui-corner-all {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

#submit {
  padding: 1px 2px;
}

.salutation {
  font-size: 18px;
  text-align: left;
}
.fontFamily {
  font-family: Arial;
  text-align: left;
}

.fontSizeP {
  font-size: 15px;
  line-height: 30px;
}
.email {
  display: block;
  width: 95%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.submitBtn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}
.submitBtn:hover,
.submitBtn:focus {
  color: #333;
  text-decoration: none;
}
.submitBtn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.greenFont {
  color: #62a744;
}
.green {
  background-color: #62a744;
  color: #ffffff;
}
</style>
