import { POST } from "./restlayer";

const actions = {
  async mail(NULL, mailId) {
    return POST("unsubscribe/email/create", mailId);
  },
};

export default {
  namespaced: true,
  actions,
};
