import Vue from "vue";
import Vuex from "vuex";
import unsubscribe from "./unsubscribe";

Vue.use(Vuex);
const modules = {
  unsubscribe,
};
export default function () {
  const Store = new Vuex.Store({ modules });
  return Store;
}
