import axios from "axios";
import { assign } from "lodash";

// const serverUrls = "https://b4fe-103-118-172-2.ngrok.io/estatementportal/api";
const serverUrls = "https://steele.epayportals.com/api";
const state = {
  serverUrl: serverUrls,
};

const getUrl = (url) => [state.serverUrl, url].join("/");

const RAW = {
  GET: axios.get,
  POST: axios.post,
  PUT: axios.put,
  PATCH: axios.patch,
  DELETE: axios.delete,
};

// exported methods
async function GET(url) {
  const URL = getUrl(url);
  try {
    const response = await axios.get(URL, assign({}));
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
}

async function POST(url, params) {
  const URL = getUrl(url);
  try {
    const response = await axios.post(URL, params, {});
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
}

export { RAW, GET, POST };
